import { Img } from 'component/FrameL';
import React, { useState, useEffect } from 'react';
import { IoMdMenu, IoMdClose } from 'react-icons/io';
import { Link, withRouter, useLocation } from 'react-router-dom';

import './style.css';

function DesktopNavbar({ CoreData }) {
  const { hotelLogo, bookingEngineUrl } = CoreData;
  const [isOpen, setIsOpen] = useState(true);

  const { pathname } = useLocation();
  return (<>
    <div className={`navbar__bg-container ${isOpen ? 'visible' : ''}`}>
      <ul className="xl:container navbar__content h-12 text-white tracking-widest uppercase mx-auto flex justify-center items-center text-center" role="navigation" >
        <li>
          <Link className={pathname === '/rooms' ? 'active' : undefined} to="/rooms">
            Rooms
          </Link>
        </li>
        <li>
          <Link className={pathname === '/gallery' ? 'active' : undefined} to="/gallery">
            Gallery
          </Link>
        </li>
        <li>
          <Link className={pathname === '/dining' ? 'active' : undefined} to="/dining">
            Dining
          </Link>
        </li>
        <li className='relative bg-black navbar__logo--width mx-4 self-start'>
          <Link className={pathname === '/' ? 'active' : undefined} to="/">
            <Img className='navbar__logo--width' Image={hotelLogo}></Img>
          </Link>
        </li>
        <li>
          <Link className={pathname === '/insiders-guide' ? 'active' : undefined} to="/insiders-guide">
            Insider's Guide
          </Link>
        </li>
        <li>
          <Link className={pathname === '/about' ? 'active' : undefined} to="/about">
            About
          </Link>
        </li>
        <li>
          <Link className={pathname === '/press' ? 'active' : undefined} to="/press">
            Press
          </Link>
        </li>
      </ul>
      <div className={`fixed top-0 right-0 flex flex-row z-10`}>
        <a
          href={bookingEngineUrl || process.env.REACT_APP_DEFAULT_BOOK_NOW}
          target="_blank"
          rel="noopener noreferrer"
          aria-label="Book Now Link"
          className="bg-pink-300 px-4 py-3 text-sans-serif uppercase no-underline">
          Book Now
        </a>
      </div>
    </div>
  </>
  );
}


function MobileNavbar({ CoreData }) {
  const { hotelLogo, bookingEngineUrl } = CoreData;
  const [isOpen, setIsOpen] = useState(false);
  return (<div className="fixed z-20 text-white">

    <div className={`fixed mt-4 mr-4 top-0 right-0 cluster-l text-black`}>
      <a
        className='no-underline uppercase btn bg-pink-300 text-sans-serif'
        target="_blank"
        rel="noopener noreferrer"
        href={bookingEngineUrl || process.env.REACT_APP_DEFAULT_BOOK_NOW}>
        Book Now
      </a>
      <button className={`p-1 bg-pink-300`} onClick={() => {
        setIsOpen(true);
      }}>
        <IoMdMenu className="text-4xl"></IoMdMenu>
      </button>
    </div>
    <div className={`text-2xl uppercase text-sans-serif items-center navbar-bg-color stack-l mobile-nav__container ${isOpen ? 'visible' : ''}`} style={{ '--justify': 'center' }}>
      <button className='absolute top-0 right-0'>
        <IoMdClose className="icon m-4" onClick={() => {
          setIsOpen(false);
        }} />
      </button>

      <Link to="/" className='max-w-xs' onClick={() => {
        setIsOpen(false);
      }}>
        <Img Image={hotelLogo}></Img>
      </Link>
      <Link to="/rooms" onClick={() => {
        setIsOpen(false);
      }}>
        Rooms
      </Link>
      <Link to="/gallery" onClick={() => {
        setIsOpen(false);
      }}>
        Gallery
      </Link>
      <Link to="/dining" onClick={() => {
        setIsOpen(false);
      }}>
        Dining
      </Link>
      <Link to="/insiders-guide" onClick={() => {
        setIsOpen(false);
      }}>
        Insider's Guide
      </Link>
      <Link to="/about" onClick={() => {
        setIsOpen(false);
      }}>
        About
      </Link>
      <Link to="/press" onClick={() => {
        setIsOpen(false);
      }}>
        Press
      </Link>
    </div>
  </div>
  );
}

const NavBar = ({ CoreData }) => {
  return (
    <React.Fragment>
      <div className="hide-on-lg">
        <DesktopNavbar CoreData={CoreData} />
      </div>
      <div className="block lg:hidden">
        <MobileNavbar CoreData={CoreData} />
      </div>
    </React.Fragment>);
}

export default withRouter(NavBar);