import React from 'react';
import ReactMarkdown from 'react-markdown';
import { LazyLoadImage as Img } from 'react-lazy-load-image-component';
import { Link } from 'react-router-dom';

import BespokeLogo from "../../assets/bespoke_footer_icon.png";
import Kayak from "assets/kayak-travel-award.png";

import './style.css';

const Footer = ({ CoreData }) => {
    const { address, phoneNumber, email } = CoreData;

    return <>
        <footer className="footer__container relative bg-black px-4 md:px-8 flex flex-col md:flex-row justify-between text-lg text-white items-center z-0">
            <div className="stack-l w-1/2 md:w-4/12 text-center md:text-left ">
                <ReactMarkdown>{address}</ReactMarkdown>
                <div>
                    <a className='tailwind-block' href={`mailto:${email}`}>{email}</a>
                    <a className='tailwind-block' href={`tel:${phoneNumber}`}>{phoneNumber}</a>
                </div>
            </div>
            <div className="stack-l w-1/2 md:w-4/12 text-center items-center" style={{ "--space": "var(--s-3)" }}>
                <Link to="/policies">
                    Policies
                </Link>
                <div className='cluster-l'>
                    <a href="http://www.kayak.co.uk/Lenox-Hotels-The-Cornell-Inn-Lenox.111427.ksp" target='_blank'>
                        <Img className='max-w-[150px]' src={Kayak} alt="Kayak Travel Award" />
                    </a>
                </div>
            </div>
            <div className="stack-l w-full md:w-4/12">
                <div className="flex flex-col items-center md:items-end">
                    <a target='_blank' href="https://www.bespoke.house/">
                        <Img src={BespokeLogo} alt="Bespoke Logo" />
                    </a>
                </div>
            </div>
        </footer>
        {/* <div className="relative bg-cover h-8 footer__overlay z-0">
        </div> */}
    </>;
};

export default Footer;
